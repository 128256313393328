import React from "react";

const ToggleOn = () => {
  return (
      <svg width="47" height="24" viewBox="0 0 47 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_52_195)">
          <path d="M35 0.5H12C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5H35C41.3513 23.5 46.5 18.3513 46.5 12C46.5 5.64873 41.3513 0.5 35 0.5Z" stroke="#4C8991"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M44 12C44 16.97 39.97 21 35 21C30.03 21 26 16.97 26 12C26 7.03 30.03 3 35 3C39.97 3 44 7.03 44 12Z" fill="#4C8991"/>
        </g>
        <defs>
          <clipPath id="clip0_52_195">
            <rect width="47" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>

  );
};

export default ToggleOn;
