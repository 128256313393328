import React from "react";

const ArrowUp = () => {
    return (
        <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.000516891 14.0441C0.00383759 13.7996 0.0992851 13.5654 0.26779 13.3882L13.0901 0.272749C13.172 0.185195 13.2714 0.115715 13.3817 0.0687531C13.4921 0.0217911 13.611 -0.0016186 13.731 2.14579e-05C13.9792 2.14579e-05 14.1919 0.0913851 14.3705 0.272749L27.006 13.1973C27.0931 13.2851 27.1616 13.3894 27.2075 13.5042C27.2534 13.619 27.2758 13.7419 27.2732 13.8655C27.2732 14.13 27.1846 14.3537 27.006 14.535C26.8287 14.7177 26.6105 14.8077 26.3542 14.8077C26.2321 14.8104 26.1108 14.7875 25.9981 14.7405C25.8854 14.6935 25.7837 14.6236 25.6996 14.535L13.731 2.24048L1.54824 14.7273C1.37097 14.9087 1.15688 15 0.907337 15C0.787868 15.0013 0.669443 14.9777 0.55958 14.9307C0.449717 14.8838 0.350815 14.8145 0.269156 14.7273C0.181116 14.6379 0.112822 14.5311 0.0686989 14.4137C0.0242252 14.2955 0.00158501 14.1703 0.00188065 14.0441H0.000516891Z" fill="#4C8991"/>
        </svg>
    );
};

export default ArrowUp;
