import { Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import firebase, { collection, db, doc, getDoc, } from "../../service/firebase";
import { CollapsibleContent, } from "../CustomersPage/CustomersPage";
import { BodyText } from "../OrdersPage/OrdersPage";
import NotAvailableComponent from "../../components/NotAvailableComponent/NotAvailableComponent";
import { useTranslation } from "react-i18next";
import { getPackingPageOrders } from "../../service/order-service";
import ArrowUp from "../../icons/arrow-up";
import ArrowDown from "../../icons/arrow-down";

const PackingPage = () => {
    const {t} = useTranslation()
    const [expandedRow, setExpandedRow] = useState(null);
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const {storeId} = useParams();
    const [store, setStore] = useState({});
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
                const storesRef = await collection(db, `/StoreOwners/${user.uid}/allStores`);
                const store = await getDoc(doc(storesRef, storeId));
                if (store.exists()) {
                    let data = store.data();
                    data.columnsList = JSON.parse(data.columnsList);
                    setStore(data);
                }

                const orders = await getPackingPageOrders(user.uid, storeId)
                setOrders(orders);
                setLoading(false);
            } else {
                navigate("/");
            }
        });
    }, [navigate, storeId]);

    const handleToggleCollapse = (rowIndex) => {
        if (expandedRow === rowIndex) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowIndex);
        }
    };
    if (loading) return <Loading/>;
    // Check if there are no orders
    const noOrders = orders.length === 0;
    return (
        <>
            {noOrders ? (
                <div>
                    <NotAvailableComponent
                        heading={t("No Orders Yet")}
                        subtext={t("Looks like no orders have been made yet for this store.")}
                    />{" "}
                </div>
            ) : (
                <Container maxWidth="lg" style={{paddingTop: 15}}>
                    <TableContainer sx={{backgroundColor: (theme) => theme.palette.background2}}>
                        <Table id="table" style={{tableLayout: "fixed", borderRadius: "10px 0 0 10px"}}>
                            <TableHead sx={{
                                background: (theme) => theme.palette.primary.main,
                            }}>
                                <TableRow>
                                    <TableCell className={'table-head'}
                                               sx={{
                                                   color: (theme) => theme.palette.white.main,
                                                   paddingLeft: "40px"
                                               }}>
                                        {t("Product")}
                                    </TableCell>
                                    <TableCell className={'table-head'}
                                               sx={{
                                                   color: (theme) => theme.palette.white.main,
                                                   paddingLeft: "40px"
                                               }}>
                                        {t("Quantity")}
                                    </TableCell>
                                    <TableCell className={'table-head'} sx={{color: (theme) => theme.palette.white.main}}>
                                        &nbsp;
                                    </TableCell>
                                    <TableCell className={'table-head'} sx={{color: (theme) => theme.palette.white.main}}>
                                        &nbsp;
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((order, rowIndex) => {
                                    return (
                                        <>
                                            <TableRow sx={{
                                                borderRadius: 4,
                                                borderTop: "3px solid white",
                                                // borderBottom: "4px solid white",
                                                borderColor: 'white',
                                                height: "52px",
                                                "&:first-child": {
                                                    borderTop: "12px solid white",
                                                },
                                                "&:last-child": {
                                                    borderBottom: "solid 6px white",
                                                },
                                            }}>
                                                <TableCell style={{paddingLeft: "40px"}} className='table-cell'>
                                                    <span style={{fontSize: "16px"}}>
                                                        {store.columnsList[order.id][1]}
                                                    </span>
                                                </TableCell>
                                                <TableCell style={{paddingLeft: "40px"}} className='table-cell'>
                                                    <span style={{fontSize: "16px"}}>
                                                        {order.quantity}
                                                    </span>
                                                </TableCell>
                                                <TableCell style={{paddingLeft: "380px"}} className='table-cell'>
                                                    <IconButton
                                                        onClick={() => handleToggleCollapse(rowIndex)}
                                                        sx={{color: (theme) => theme.palette.primary.main}}>
                                                        {expandedRow === rowIndex ?
                                                            (<ArrowUp/>) : (<ArrowDown/>)}
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{padding: 0}} colSpan={4}>
                                                    <Collapse
                                                        in={expandedRow === rowIndex}
                                                        timeout="auto"
                                                        unmountOnExit>
                                                        <CollapsibleContent>
                                                            {order.customers.map((customer, index) => (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        width: "100%",
                                                                        borderTop: (index === 0) ? 'solid white 3px' : 0,
                                                                        backgroundColor: "#f1f2f4",
                                                                        borderBottom: (index !== (order.customers.length - 1)) ? "1px solid lightgray" : 0,
                                                                        marginBottom: (index === (order.customers.length - 1)) ? "20px" : 0,
                                                                        paddingTop: 20,
                                                                        paddingLeft: 38,
                                                                    }}>
                                                                    <div>
                                                                        <Grid container style={{
                                                                            fontSize: 16,
                                                                            fontWeight: 400,
                                                                            lineHeight: "22px"
                                                                        }}>
                                                                            <Grid item xs={3} md={3}>
                                                                                <p style={{
                                                                                    marginTop: 0,
                                                                                    color: "#353535"
                                                                                }}>{customer.email}</p>
                                                                                <p>{customer.name}</p>
                                                                                <p style={{color: "#353535"}}>{customer.phone}</p>
                                                                            </Grid>
                                                                            <Grid item xs={2} md={2}>
                                                                                <span style={{paddingLeft: "12px"}}>
                                                                                    {customer.qty}
                                                                                </span>
                                                                            </Grid>
                                                                            <Grid item xs={3} md={3}>
                                                                                <BodyText
                                                                                    variant="footnote"
                                                                                    sx={{
                                                                                        marginBottom: 1,
                                                                                        paddingLeft: "50px",
                                                                                        fontWeight: 300,
                                                                                        fontSize: 14,
                                                                                        lineHeight: "16px",
                                                                                        fontStyle: "normal"
                                                                                    }}>
                                                                                    {t("Note/Comment")}
                                                                                </BodyText>
                                                                                <BodyText sx={{paddingLeft: "50px",}}
                                                                                          variant="body1">
                                                                                    {customer.comment}
                                                                                </BodyText>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </CollapsibleContent>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            )}
        </>
    );
};

export default PackingPage;
