import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { Box, Collapse, Grid, IconButton, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import firebase, { collection, db, doc, getDoc, getDocs, query, where } from "../../service/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { BodyText } from "../OrdersPage/OrdersPage";
import Loading from "../../components/Loading";
import NotAvailableComponent from "../../components/NotAvailableComponent/NotAvailableComponent";
import { useTranslation } from "react-i18next";
import { getCustomerOrders, getCustomersService } from "../../service/customer-service";
import ArrowUp from "../../icons/arrow-up";
import ArrowDown from "../../icons/arrow-down";
import Total from "../../components/total";

export const ItemContainer = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
}));

export const CollapsibleContent = styled(Box)(({theme}) => ({
    backgroundColor: "#fff",
    marginTop: "5px",
    transition: "height 0.3s ease-in-out",
    overflow: "hidden",
    width: "100%",
}));

const CustomerOrders = () => {
    const {t} = useTranslation()
    const [expandedRow, setExpandedRow] = useState(null);
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const {storeId} = useParams();
    const [store, setStore] = useState({});
    const [customers, setCustomers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
                let temp = [];
                const allStores = collection(db, `/StoreOwners/${user.uid}/allStores`);
                const querySnapshot = await getDocs(allStores);
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    temp.push(doc.data());
                });
                const storesRef = await collection(
                    db,
                    `/StoreOwners/${user.uid}/allStores`
                );
                const store = await getDoc(doc(storesRef, storeId));
                if (store.exists()) {
                    let data = store.data();
                    data.columnsList = JSON.parse(data.columnsList);
                    setStore(data);
                }
                getCustomersService(user.uid, storeId).then((res) => {
                    setCustomers(res);
                    setLoading(false);
                })
            } else {
                navigate("/");
            }
        });
    }, [navigate, storeId]);


    const handleToggleCollapse = (rowIndex, customer) => {
        if (expandedRow === rowIndex) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowIndex);
            getCustomerOrders(user.uid, storeId, customer.email).then((order) => {
                setOrders(order);
                // document.getElementById("customer").textContent = customer.email;
            });
        }
    };

    if (loading) return <Loading/>;
    // Check if there are no orders
    const noCustomers = customers.length === 0;
    return (
        <>
            {noCustomers ? (
                <div>
                    <NotAvailableComponent
                        heading={t("No Customers Yet")}
                        subtext={t("Looks like there are no customers yet for this store.")}
                    />{" "}
                </div>
            ) : (
                <Container maxWidth="lg">
                    <Grid container spacing={2} style={{paddingTop: 15, paddingLeft: 7}}>
                        {customers.map((customer, index) => (
                            <Grid item xs={12} key={index}>
                                <ItemContainer
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.background2,
                                    }}>
                                    <div style={{width: "100%"}}>
                                        <Typography
                                            variant="body"
                                            align="left"
                                            sx={{fontWeight: 400, paddingLeft: "15px"}}>
                                            {customer.name}
                                        </Typography>
                                    </div>
                                    <div style={{width: "100%"}}>
                                        <Typography
                                            variant="body"
                                            align="left"
                                            sx={{fontWeight: 400}}>
                                            {customer.email}
                                        </Typography>
                                    </div>
                                    <div style={{width: "100%"}}>
                                        <Typography
                                            variant="body"
                                            align="left"
                                            sx={{fontWeight: 400}}>
                                            {customer.phone}
                                        </Typography>
                                    </div>
                                    <IconButton
                                        onClick={() => handleToggleCollapse(index, customer)}
                                        sx={{color: (theme) => theme.palette.primary.main, marginRight: "86px" }}>
                                        {expandedRow === index ? (<ArrowUp/>) : (<ArrowDown/>)}
                                    </IconButton>
                                </ItemContainer>
                                {expandedRow === index && (
                                    <Collapse
                                        in={expandedRow === index}
                                        timeout={300}
                                        sx={{
                                            paddingTop: "16px",
                                        }}>
                                        <CollapsibleContent>
                                            <div style={{ paddingLeft: "20px" }}>
                                                <Typography
                                                    variant="body1"
                                                    align="left"
                                                    fontSize={"14px"}
                                                    fontWeight={300}
                                                    mb={1}>
                                                    {t("Note/Comment")}
                                                </Typography>
                                                <Typography variant="body1"
                                                            fontSize={"16px"} align="left" fontWeight={400}>
                                                    {customer.comment}
                                                </Typography>
                                            </div>
                                            {/* Display Order for each Customer */}
                                            <div>
                                                <Grid
                                                    container
                                                    key="titles"
                                                    alignItems="center"
                                                    sx={{
                                                        minHeight: "51px",
                                                        backgroundColor: (theme) => theme.palette.primary.main,
                                                        marginTop: "19px",
                                                        paddingLeft: "42px"
                                                    }}>
                                                    <Grid item xs={3} md={2} className={'table-head-container'}>
                                                        <p className={'table-head'}>
                                                            {t("Product")}
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} className={'table-head-container'}>
                                                        <p className={'table-head'}>
                                                            {t("Price")}
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} className={'table-head-container'}>
                                                        <p className={'table-head'}>
                                                            {t("Quantity")}
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} className={'table-head-container'}>
                                                        <p className={'table-head'}>
                                                            {t("Amount")}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                                {orders?.map((order, index) => {
                                                    return (
                                                        <Grid
                                                            container
                                                            key={index}
                                                            sx={{
                                                                backgroundColor: (theme) =>
                                                                    theme.palette.background2,
                                                                borderTop: "1px solid",
                                                                borderColor: (theme) =>
                                                                    alpha(theme.palette.primary.main, 0.3),
                                                                "&:first-child": {
                                                                    borderTop: "none",
                                                                },
                                                                "&:last-child": {
                                                                    borderBottom: "none",
                                                                },
                                                                minHeight: "46px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "42px"
                                                            }}>
                                                            <Grid item xs={3} md={2}>
                                                                <BodyText variant="body1">
                                                                    {store.columnsList[order.id][1]}
                                                                </BodyText>
                                                            </Grid>
                                                            <Grid item xs={3} md={2}>
                                                                <BodyText variant="body1">
                                                                    {store.columnsList[order.id][2]}{" "}
                                                                    {store?.currency}
                                                                </BodyText>
                                                            </Grid>
                                                            <Grid item xs={3} md={2}>
                                                                <BodyText variant="body1">
                                                                    {order.quantity}
                                                                </BodyText>
                                                            </Grid>
                                                            <Grid item xs={3} md={2}>
                                                                <BodyText variant="body1">
                                                                    {(
                                                                        parseFloat(store.columnsList[order.id][2]) *
                                                                        parseFloat(order.quantity)
                                                                    ).toFixed(2)}{" "}
                                                                    {store?.currency}
                                                                </BodyText>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                                <Total>
                                                    {orders
                                                        .reduce((prev, next) => {
                                                            return (
                                                                prev +
                                                                parseFloat(store.columnsList[next.id][2]) *
                                                                parseFloat(next.quantity)
                                                            );
                                                        }, 0)
                                                        .toFixed(2)}{" "}
                                                    {store?.currency}
                                                </Total>
                                            </div>
                                        </CollapsibleContent>
                                    </Collapse>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            )}
        </>
    );
};

export default CustomerOrders;
