import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './locales/en/translation.json'
import PT from './locales/pt/translation.json'

i18n
    .use(initReactI18next)
    .init({
        resources: {
            EN: {translation: EN},
            PT: {translation: PT}
        },
        lng: 'EN',
        fallbackLng: 'EN',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;