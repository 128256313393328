import React from "react";
import ReactJoyride, { STATUS } from 'react-joyride';
import { useTranslation } from "react-i18next";

const buttonReset = {
    backgroundColor: "#4c8991",
    border: 0,
    borderRadius: 0,
    color: "#555",
    outline: "none",
    lineHeight: 1,
    padding: 8,
    WebkitAppearance: "none",
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 14,
    fontFamily: 'Open Sans',
};

const OnboardingTooltip = () => {
    const {t} = useTranslation()

    const steps = [
        {
            target: "#outlined-basic",
            content: t("Paste the data you copied from a spreadsheet here (1/5)"),
            disableBeacon: true,
            className:"first-step"
        },
        {
            target: "#step2",
            content:
                t("Click “Create PopStore” to convert your data into a PopStore (2/5)"),
            disableBeacon: true,
            placement: "auto"
        },
        {
            target: "#step3",
            content: t("View analytics about your PopStores (3/5)"),
            disableBeacon: true,
            placement: "top-start"
        },
        {
            target: "#step4",
            content:
                t("Copy the link of your PopStore to share it with your customers (4/5)"),
            disableBeacon: true,
            placement: "top"
        },
        {
            target: "#step5",
            content:
                t("Click on your PopStore to view & edit your store, order, customers, and packing (5/5)"),
            disableBeacon: true,
            placement: "top-start"
        },
    ];


    const  handleJoyrideCallback = (data) => {
        const { status, type } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem("tooltip", JSON.stringify(data))
        }
    };
    return (
        <div>
            <ReactJoyride
                callback={handleJoyrideCallback}
                continuous={true}
                steps={steps}
                disableCloseOnEsc
                disableOverlayClose
                disableScrollParentFix
                showSkipButton
                disableOverlay
                styles={{
                    options: {
                        arrowColor: "#4c8991",
                        backgroundColor: "#4c8991",
                        primaryColor: "#4c8991",
                        textColor: "#fff",
                        width: "274px",
                        height: "161px",
                        position: "relative",
                        zIndex: 9999,
                        textAlign: "left"
                    },
                    tooltipContent: {
                        padding: "20px 10px",
                        textAlign: "left",
                        fontWeight: 300,
                        fontSize: "16px",
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        lineHeight: "22px",
                        marginTop: 10
                    },
                    buttonNext: {
                        ...buttonReset,
                        backgroundColor: "#4c8991",
                        color: "#fff",
                        right: 0,
                        top: 0,
                    },
                    buttonBack: {
                        color: "#fff",
                        position: "absolute",
                        top: 5,
                        left: 5,
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: 300,
                        fontSize: 14
                    },
                    buttonClose: {
                        display: "none"
                    },
                    buttonSkip: {
                        color: "#fff",
                        position: "absolute",
                        top: 5,
                        right: 15,
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: 300,
                        fontSize: 14
                    }
                }}
                locale={{
                    last: t("End"),
                    skip: t("Skip"),
                    next: t("Next"),
                    back: t("Back")
                }}>
                Test
            </ReactJoyride>
        </div>
    );
};

export default OnboardingTooltip;
