import { Button, Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CopyIcon from "../../icons/copyIcon";
import { useTranslation } from "react-i18next";

const CardContainer = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background2,
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: theme.spacing(2),
  borderRadius: "4px",
  marginTop: theme.spacing(1),
  textDecoration: "none",
  width: "100%",
  height: "72px",
}));

const ButtonContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: { marginTop: theme.spacing(2) },
}));

const StoreCardDefault = () => {

  const {t} = useTranslation()
  return (
    <>
      <CardContainer>
        <Grid container alignItems="center">
          <Grid item xs={6} md={6}>
            <Typography
                id="step5"
                variant="body3"
                color="majorBlack"
                sx={{
                  flexGrow: 1,
                  fontWeight: "regular",
                  textDecoration: "none !important",
                }}>
              {t('My PopStore')}
            </Typography>
          </Grid>
          <ButtonContainer container item spacing={2} xs={12} md={6}>
            <Grid item xs={12} sm={4} alignItems="center">
              <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "139px", height: "40px" }}>
                {t("Orders")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button variant="contained" color="primary" sx={{ width: "139px", height: "40px" }}>
                {t("Duplicate store")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                  id="step4"
                  variant="contained"
                  startIcon={<CopyIcon />}
                  sx={{ width: "139px", height: "40px" }}>
                {t("Copy link")}
              </Button>
            </Grid>
          </ButtonContainer>
        </Grid>
      </CardContainer>
    </>
  );
};

export default StoreCardDefault;
