import React from "react";

const Total = ({ children }) => {
    return (
        <div style={{
            marginLeft: "auto",
            background: '#F1F2F4',
            borderRadius: 4,
            width: 341,
            height: 62,
            marginTop: 13,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '16px',
            color: '#353535',
            gap: 46
        }}>
            <p>Total:</p>
            <div>
                {children}
            </div>
        </div>
    )
}
export default Total