import React from "react";

const HomeIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6154 5.49378L10.3846 0.109161C10.2677 0.0416469 10.135 0.00610352 10 0.00610352C9.86497 0.00610352 9.73232 0.0416469 9.61539 0.109161L0.384615 5.49378C0.268355 5.5609 0.171685 5.65727 0.104211 5.77333C0.0367376 5.88938 0.000810285 6.02107 0 6.15531V20.0015C0 20.2055 0.0810437 20.4011 0.225302 20.5454C0.369561 20.6897 0.565218 20.7707 0.769231 20.7707H7.69231C7.89632 20.7707 8.09198 20.6897 8.23624 20.5454C8.3805 20.4011 8.46154 20.2055 8.46154 20.0015V14.6169H11.5385V20.0015C11.5385 20.2055 11.6195 20.4011 11.7638 20.5454C11.908 20.6897 12.1037 20.7707 12.3077 20.7707H19.2308C19.4348 20.7707 19.6304 20.6897 19.7747 20.5454C19.919 20.4011 20 20.2055 20 20.0015V6.15531C19.9992 6.02107 19.9633 5.88938 19.8958 5.77333C19.8283 5.65727 19.7316 5.5609 19.6154 5.49378ZM18.4615 19.2322H13.0769V13.8476C13.0769 13.6436 12.9959 13.448 12.8516 13.3037C12.7074 13.1594 12.5117 13.0784 12.3077 13.0784H7.69231C7.4883 13.0784 7.29264 13.1594 7.14838 13.3037C7.00412 13.448 6.92308 13.6436 6.92308 13.8476V19.2322H1.53846V6.59378L10 1.66301L18.4615 6.59378V19.2322Z"
        fill="#4C8991"
      />
    </svg>
  );
};

export default HomeIcon;
