import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import NotAvailableComponent from "../../components/NotAvailableComponent/NotAvailableComponent";
import firebase, { collection, db, doc, getDoc, } from "../../service/firebase";
import { useTranslation } from "react-i18next";
import { getOrderService, getOrderTotal } from "../../service/order-service";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Total from "../../components/total";

export const HeaderText = styled(Typography)(({theme}) => ({
    fontWeight: 500,
    fontSize: "14px",
    color: "#fff",
}));
export const BodyText = styled(Typography)(({theme}) => ({
    fontWeight: 400,
    fontSize: "14px",
}));

const OrdersPage = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const {storeId} = useParams();
    const [store, setStore] = useState({});
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
                const storesRef = await collection(
                    db,
                    `/StoreOwners/${user.uid}/allStores`
                );
                const store = await getDoc(doc(storesRef, storeId));
                if (store.exists()) {
                    let data = store.data();
                    data.columnsList = JSON.parse(data.columnsList);
                    setStore(data);
                }
                getOrderService(user.uid, storeId).then((res) => {
                    setOrders(res);
                    setLoading(false);
                }).catch(() => setLoading(false))
            } else {
                navigate("/");
            }
        });
    }, [navigate, storeId]);

    // Check if there are no orders
    const noOrders = orders.length === 0;

    if (loading) return <Loading/>;
    return (
        <Container maxWidth="lg">
            {noOrders ? (
                <div>
                    <NotAvailableComponent
                        heading={t("No Orders Yet")}
                        subtext={t("Looks like no orders have been made yet for this store.")}
                    />{" "}
                </div>
            ) : (
                <div style={{backgroundColor: "#fff", marginTop: 15}}>
                    <TableContainer sx={{backgroundColor: (theme) => theme.palette.background2}}>
                        <Table id="table" style={{tableLayout: "fixed"}}>
                            <TableHead sx={{background: (theme) => theme.palette.primary.main}}>
                                <TableRow>
                                    <TableCell className={'table-head'}
                                               sx={{color: (theme) => theme.palette.white.main}}>
                                        {t("Product")}
                                    </TableCell>
                                    <TableCell className={'table-head'}
                                               sx={{color: (theme) => theme.palette.white.main}}>
                                        {t("Price")}
                                    </TableCell>
                                    <TableCell className={'table-head'}
                                               sx={{color: (theme) => theme.palette.white.main}}>
                                        {t("Quantity")}
                                    </TableCell>
                                    <TableCell className={'table-head'}
                                               sx={{color: (theme) => theme.palette.white.main}}>
                                        {t("Amount")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders?.map((order, index) => {
                                    return (
                                        <TableRow
                                            sx={{
                                                borderTop: "2px solid #4C8991",
                                                borderColor: (theme) =>
                                                    alpha(theme.palette.primary.main, 0.3),
                                                "&:first-child": {
                                                    borderTop: "none",
                                                },
                                                "&:last-child": {
                                                    borderBottom: "solid 2px white",
                                                },
                                            }}>
                                            <TableCell style={{
                                                fontFamily: 'Open Sans',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '16px',
                                                color: '#353535',
                                            }}>
                                                {store.columnsList[order.id][1]}
                                            </TableCell>
                                            <TableCell style={{
                                                fontFamily: 'Open Sans',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '16px',
                                                color: '#353535',
                                            }}>
                                                {store.columnsList[order.id][2]} {store?.currency}
                                            </TableCell>
                                            <TableCell style={{
                                                fontFamily: 'Open Sans',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '16px',
                                                color: '#353535',
                                            }}>
                                                {order.quantity}
                                            </TableCell>
                                            <TableCell style={{
                                                fontFamily: 'Open Sans',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '16px',
                                                color: '#353535',
                                            }}>
                                                {getOrderTotal(store.columnsList[order.id][2], order.quantity, store?.currency)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Total>
                        {orders
                            ?.reduce((prev, next) => {
                                return (
                                    prev +
                                    parseFloat(store.columnsList[next.id][2]) *
                                    parseFloat(next.quantity)
                                );
                            }, 0)
                            .toFixed(2)}{" "}
                        {store?.currency}
                    </Total>
                </div>
            )}
        </Container>
    );
};

export default OrdersPage;
