import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { alpha, useTheme } from "@mui/material/styles";
import firebase, { collection, db, doc, serverTimestamp, setDoc, } from "../../service/firebase";
import {
    Grid,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import styles from "../../components/DataTable/Sheets.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { StyledTextField } from "../../components/Styles/styledTextField";
import { CreatePopstoreBox } from "../../components/Styles/styledCreatePopstoreBox.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ErrorAlert, SuccessAlert, } from "../../components/Styles/styledNotificationAlerts";
import { useTranslation } from "react-i18next";
import DropDownIcon from "../../icons/drop-down-icon";

export const eurocurrencies = {
    CAD: "Canadian dollar",

    CHF: "Swiss franc",

    DKK: "Danish krone",

    EUR: "Euro",

    GBP: "Pound sterling",

    NOK: "Norwegian krone",

    SEK: "Swedish krona",

    USD: "United States dollar",
};

export const updateCurrencyColumn = async (e, country, currency) => {
    e.preventDefault();
    if (document.getElementById(`currency`)) {
        if (currency === "EUR") {
            document.getElementById(`currency`).textContent = currency;
        } else {
            document.getElementById(`currency`).textContent =
                country + " - " + currency;
        }
    }
};

const NewPopstore = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [storeName, setStoreName] = useState("");
    const [storeOwner, setStoreOwner] = useState("");
    const [alert, setAlert] = useState({type: "", message: ""});
    const [storeDescription, setStoreDescription] = useState("");
    const [storeCurrency, setStoreCurrency] = useState("Euro");
    const [sheetData, setSheetData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const {t} = useTranslation()
    const [dbColumns] = useState([
        "Select Column",
        "Name",
        "Reference ID",
        "Price",
        "Ignore",
    ]);
    const [col, setCol] = useState({});

    const handleAlert = async (type, message) => {
        await setAlert({type, message});
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenModal = async (e) => {
        setOpenModal(true);
    };

    const [currencies] = useState(eurocurrencies);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
                setStoreOwner(user.email);
            } else {
                navigate("/");
            }
            if (localStorage.getItem("sheetData") !== null) {
                let data = JSON.parse(localStorage.getItem("sheetData"));
                setSheetData(data);
                setColumns(data[0].cells);
            } else {
                navigate("/");
            }
            localStorage.setItem(
                "columns",
                JSON.stringify({
                    Name: -1,
                    "Reference ID": -1,
                    Price: -1,
                    Ignore: 9,
                })
            );
            setCol({
                Name: -1,
                "Reference ID": -1,
                Price: -1,
                Ignore: 9,
            });
        });
    }, [navigate]);

    const saveStore = async (e) => {
        e.preventDefault();
        let columns = JSON.parse(localStorage.getItem("columns"));
        let referenceIdColumn = columns["Reference ID"];
        let priceColumn = columns["Price"];
        let nameColumn = columns["Name"];
        let descriptionColumn = columns["Description"];

        if (nameColumn === -1) {
            await handleAlert(
                "error",
                t("Please select a column for name of products.")
            );
            handleOpenModal();
            return;
        }

        if (priceColumn === -1) {
            await handleAlert(
                "error",
                t("Please select a column for price of products")
            );
            handleOpenModal();
            return;
        }

        if (storeName.trim() === "") {
            await handleAlert("error", "Please select name of popstore");
            handleOpenModal();
            return;
        }

        if (storeOwner.trim() === "") {
            await handleAlert("error", "Please add an email for postore owner.");
            handleOpenModal();
            return;
        }

        if (storeDescription.trim() === "") {
            await handleAlert("error", "Please add description of popstore");
            handleOpenModal();
            return;
        }

        let productsPrices = [];

        let products = [];

        if (referenceIdColumn === -1) {
            for (let i = 0; i < sheetData.length; i++) {
                if (
                    sheetData[i].cells[nameColumn] === undefined ||
                    sheetData[i].cells[priceColumn] === undefined
                ) {
                    continue;
                }
                products.push([
                    i,
                    sheetData[i].cells[nameColumn],
                    sheetData[i].cells[priceColumn],
                    sheetData[i].cells[descriptionColumn]
                        ? sheetData[i].cells[descriptionColumn]
                        : "",
                ]);
                productsPrices.push(parseFloat(sheetData[i].cells[priceColumn]));
            }
        } else {
            for (let i = 0; i < sheetData.length; i++) {
                if (
                    sheetData[i].cells[nameColumn] === undefined ||
                    sheetData[i].cells[priceColumn] === undefined ||
                    sheetData[i].cells[referenceIdColumn] === undefined
                ) {
                    continue;
                }
                products.push([
                    sheetData[i].cells[referenceIdColumn],
                    sheetData[i].cells[nameColumn],
                    sheetData[i].cells[priceColumn],
                    sheetData[i].cells[descriptionColumn]
                        ? sheetData[i].cells[descriptionColumn]
                        : "",
                ]);
                productsPrices.push(parseFloat(sheetData[i].cells[priceColumn]));
            }
        }

        // Reset the column name to 0
        productsPrices[0] = 0;
        if (productsPrices.includes(NaN)) {
            await handleAlert("error", "Price for all products must be a number.");
            handleOpenModal();
            return;
        }

        const storesRef = doc(collection(db, "StoreOwners"), user.uid);
        const storeRef = doc(collection(storesRef, "allStores"));

        let store = {
            storeName: storeName,
            storeOwner: storeOwner,
            description: storeDescription,
            currency: storeCurrency,
            ownerID: user.uid,
            storeID: storeRef.id,
            createAt: serverTimestamp(),
            columnsList: JSON.stringify(products),
            link: `https://popsto.re/store/${user.uid}/${storeRef.id}`,
            columns: columns,
            locked: false,
        };

        async function createStore() {
            await setDoc(storeRef, store);
            localStorage.removeItem("columns");
            localStorage.removeItem("sheetData");
            await handleAlert(
                "success",
                t("Your PopStore has been created successfully!")
            );
        }

        createStore().then(() => handleOpenModal());
    };

    const cancelStore = async (e) => {
        e.preventDefault();
        localStorage.removeItem("sheetData");
        localStorage.removeItem("columns");
        navigate("/");
    };
    const updateCurrencyValue = (e) => {
        setStoreCurrency(e.target.value);
    };
    const updateSelectedColumn = async (e, column, index, c) => {
        let cols = localStorage.getItem("columns");
        cols = JSON.parse(cols);
        cols[column] = index;
        let tempValues = Object.values(cols);
        if (tempValues.includes(index)) {
            let key = Object.keys(cols).find(
                (key) => cols[key] === index && key !== column
            );
            if (key !== undefined) {
                cols[key] = -1;
            }
        }

        // Check price column for numeric values
        let productsPrices = [];
        for (let i = 0; i < sheetData.length; i++) {
            if (
                (sheetData[i].cells[cols["Price"]] === undefined &&
                    cols["Price"] !== -1) ||
                (sheetData[i].cells[cols["Name"]] === undefined && cols["Name"] !== -1)
            ) {
                continue;
            }
            productsPrices.push(parseFloat(sheetData[i].cells[cols["Price"]]));
        }

        productsPrices[0] = 0;
        if (cols["Price"] !== -1 && productsPrices.includes(NaN)) {
            handleAlert("error", "Price for all products must be a number.");
        }
        // Same column information
        setCol(cols);
        if (document.getElementById(`${c}-${index}`)) {
            document.getElementById(`${c}-${index}`).textContent = column;
        }
        localStorage.setItem("columns", JSON.stringify(cols));
    };

    return (
        <div>
            <Grid spacing={1} sx={{marginBottom: 4, width: "100%"}}>
                <div className={'store-header'}>
                    <div className={'store-title-div'}>
                        {!isMobile && (
                            <div>
                                <IconButton size="large" color="primary" onClick={cancelStore}>
                                    <ArrowBackIosIcon fontSize="32"/>
                                </IconButton>
                            </div>
                        )}
                        <Typography variant="h2" className='create-store-title'>
                            {t('Create my Popstore')}
                        </Typography>
                    </div>
                    <div className={'store-actions'}>
                        <div>
                            <Button
                                className={'btn-create-store'}
                                color="primary"
                                variant="contained"
                                onClick={saveStore}>
                                {t('Complete Popstore')}
                            </Button>
                            {alert.type === "error" && (
                                <ErrorAlert
                                    open={openModal}
                                    onClose={handleCloseModal}
                                    message={alert.message}
                                />
                            )}
                            {alert.type === "success" && (
                                <SuccessAlert
                                    open={openModal}
                                    onClose={() => navigate("/")}
                                    message={alert.message}
                                    navigate={() => navigate("/")}
                                />
                            )}
                        </div>
                        <div>
                            <Link className={'cancel-store'} href="#" onClick={cancelStore}>
                                {t("Cancel")}
                            </Link>
                        </div>
                    </div>
                </div>
            </Grid>
            <CreatePopstoreBox maxWidth="lg">
                <form onSubmit={saveStore}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item container spacing={2} xs={12} md={6}>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <Typography variant="body" color="text.main">
                                        {t("Store Name")}
                                    </Typography>
                                </div>
                                <StyledTextField className={'custom-input'}
                                                 fullWidth
                                                 id="outlined-basic"
                                                 label=""
                                                 helperText=""
                                                 variant="outlined"
                                                 value={storeName}
                                                 onChange={(e) => setStoreName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <Typography variant="body" color="text.main">
                                        {t("Contact")}
                                    </Typography>
                                </div>
                                <StyledTextField className={'custom-input'}
                                                 fullWidth
                                                 id="outlined-basic"
                                                 label=""
                                                 helperText=""
                                                 type="email"
                                                 variant="outlined"
                                                 value={storeOwner}
                                                 onChange={(e) => setStoreOwner(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div>
                                    <Typography variant="body" color="text.main">
                                        {t("Store Description")}
                                    </Typography>
                                </div>
                                <StyledTextField className={'custom-textarea'}
                                                 multiline
                                                 fullWidth
                                                 id="outlined-basic"
                                                 label=""
                                                 helperText=""
                                                 variant="outlined"
                                                 value={storeDescription}
                                                 onChange={(e) => setStoreDescription(e.target.value)}
                                                 inputProps={{
                                                     style: {
                                                         whiteSpace: "pre-wrap",
                                                         wordWrap: "break-word",
                                                     },
                                                 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end" xs={12} md={6}>
                            <Grid item width="50%" sx={{marginTop: 6}}>
                                <div>
                                    <Typography variant="body" color="text.main">
                                        {t("Currency")}
                                    </Typography>
                                </div>
                                <Select
                                    className={'custom-select'}
                                    fullWidth={true}
                                    label=""
                                    value={storeCurrency}
                                    onChange={updateCurrencyValue}
                                    id="currency"
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                bgcolor: "#fff",
                                                maxHeight: "200px",
                                                "& .MuiMenuItem-root": {
                                                    padding: 2,
                                                },
                                            },
                                        },
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    IconComponent={() => (
                                        <DropDownIcon/>
                                    )}
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "1px solid",
                                            borderColor: "#353535",
                                            borderRadius: "6px",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "&:hover": {
                                                borderColor: (theme) => theme.palette.primary.main,
                                            },
                                            "&:focused": {
                                                borderColor: (theme) => theme.palette.primary.main,
                                            },
                                        },
                                    }}>
                                    {Object.keys(currencies).map((currency, i) => (
                                        <MenuItem
                                            key={`${i}`}
                                            value={currencies[currency]}
                                            onClick={(e) =>
                                                updateCurrencyColumn(e, currency, currencies[currency])
                                            }
                                        >
                                            {currency} - {currencies[currency]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <div className="create-table-wrapper">
                    <TableContainer
                        sx={{
                            backgroundColor: (theme) => theme.palette.background2,
                            marginTop: "50px",
                        }}
                    >
                        <Table style={{tableLayout: "fixed"}}>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        borderTop: "2px solid",
                                        paddingLeft: 20,
                                        borderColor: (theme) =>
                                            alpha(theme.palette.primary.main, 0.3),
                                        "&:first-child": {
                                            borderTop: "solid 2px white",
                                        },
                                        "&:last-child": {
                                            borderBottom: "solid 2px #EDF3F4",
                                        },
                                    }}>
                                    {columns?.map((column, index) => (
                                        <TableCell key={index} style={{paddingLeft: 50}}>
                                            <Select
                                                IconComponent={() => (
                                                    <DropDownIcon/>
                                                )}
                                                className={'custom-select'}
                                                style={{maxWidth: 191}}
                                                fullWidth={true}
                                                id={`${column}-${index}`}
                                                label=""
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            bgcolor: "#fff",
                                                            "& .MuiMenuItem-root": {
                                                                padding: 2,
                                                            },
                                                        },
                                                    },
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    },
                                                    getContentAnchorEl: null,
                                                }}
                                                sx={{
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "1px solid",
                                                        borderColor: "#353535",
                                                        borderRadius: "6px",
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        "&:hover": {
                                                            borderColor: (theme) =>
                                                                theme.palette.primary.main,
                                                        },
                                                        "&:focused": {
                                                            borderColor: (theme) =>
                                                                theme.palette.primary.main,
                                                        },
                                                    },
                                                }}
                                            >
                                                {dbColumns.map((dbColumn, i) => (
                                                    <MenuItem
                                                        disabled={
                                                            col[dbColumn] !== -1 &&
                                                            dbColumn !== "Ignore" &&
                                                            col[dbColumn] !== index
                                                        }
                                                        onClick={(e) =>
                                                            updateSelectedColumn(e, dbColumn, index, column)
                                                        }
                                                        key={`${index}-${dbColumn}`}>
                                                        {dbColumn}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sheetData?.map((row, rowIndex) => {
                                    return (
                                        <TableRow key={`row-${rowIndex}`}

                                                  sx={{
                                                      borderTop: "1px solid",
                                                      paddingLeft: 20,
                                                      borderColor: (theme) =>
                                                          alpha(theme.palette.primary.main, 0.3),
                                                      "&:first-child": {
                                                          borderTop: "solid 2px white",
                                                      },
                                                      "&:last-child": {
                                                          borderBottom: "solid 2px #EDF3F4",
                                                      },
                                                  }}
                                        >
                                            {row.cells.map((cell, cellIndex) => (
                                                <TableCell style={{paddingLeft: 50}}
                                                           key={`cell-${rowIndex}-${cellIndex}`}
                                                           className={styles["cell"]}
                                                >
                                                    {cell}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </CreatePopstoreBox>

            <div className={'store-header'} style={{
                marginTop: `${isMobile ? "10px" : "56px"}`,
                marginBottom: `${isMobile && "10px"}`
            }}>
                <div className={'store-title-div'}>
                </div>
                <div className={'store-actions'}>
                    <div>
                        <Button
                            className={'btn-create-store'}
                            color="primary"
                            variant="contained"
                            onClick={saveStore}>
                            {t("Create PopStore")}
                        </Button>
                        {alert.type === "error" && (
                            <ErrorAlert
                                open={openModal}
                                onClose={handleCloseModal}
                                message={alert.message}
                            />
                        )}
                        {alert.type === "success" && (
                            <SuccessAlert
                                open={openModal}
                                onClose={() => navigate("/")}
                                message={alert.message}
                                navigate={() => navigate("/")}
                            />
                        )}
                    </div>
                    <div>
                        <Link className={'cancel-store'} href="#" onClick={cancelStore}>
                            {t("Cancel")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewPopstore;
