import { collection, db, getDocs } from "./firebase";

export const getOrderService = async (userId, storeId) => {
    const ordersRef = collection(
        db,
        `/StoreOwners/${userId}/allStores/${storeId}/Orders`
    );
    const querySnapshot = await getDocs(ordersRef);
    let temp = [];
    querySnapshot.forEach((doc) => {
        let d = doc.data();
        d.order = JSON.parse(d.order);
        temp.push(d);
    });
    let orders = [];
    temp.forEach((o) => {
        o.order.forEach((p) => {
            if (p !== null) {
                // check if order already exists
                let index = orders.findIndex((e) => e.id === p.id);
                if (index === -1) {
                    orders.push(p);
                } else {
                    orders[index].quantity += p.quantity;
                }
            }
        });
    });

    return orders;
}

export const getOrderTotal = (price, qty, currency = '') => {
    const total  = (parseFloat(price) * parseFloat(qty)).toFixed(2)

    return total + " " + currency
}

export const getPackingPageOrders = async (userId, storeId) => {
    const ordersRef = collection(db, `/StoreOwners/${userId}/allStores/${storeId}/Orders`);
    const querySnapshot = await getDocs(ordersRef);
    let tempOrders = [];
    querySnapshot.forEach((doc, i) => {
        let d = doc.data();
        tempOrders.push(d);
    });
    let orders = [];
    tempOrders.forEach((o) => {
        JSON.parse(o.order).forEach((p) => {
            if (p !== null) {
                let index = orders.findIndex((e) => e.id === p.id);
                if (index === -1) {
                    orders.push({
                        ...p,
                        customers: [{
                            email: o.email,
                            phone: o.phone,
                            name: o.name,
                            comment: o.comment,
                            qty: p.quantity
                        }]
                    });
                } else {
                    orders[index].quantity += p.quantity;
                    orders[index].customers.push({
                        email: o.email,
                        phone: o.phone,
                        name: o.name,
                        comment: o.comment,
                        qty: p.quantity
                    });
                }
            }
        });
    });

    return orders;
}