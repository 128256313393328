export const euroCurrencies = {
    "CAD": "Canadian dollar",

    "CHF": "Swiss franc",

    "DKK": "Danish krone",

    "EUR": "Euro",

    "GBP": "Pound sterling",

    "NOK": "Norwegian krone",

    "SEK": "Swedish krona",

    "USD": "United States dollar"
};