import React from "react";

const MBIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 0.125C10.4062 0.125 10.125 0.40625 10.125 0.75V2.65625C4.55625 2.98125 0.125 7.6 0.125 13.25C0.125 16.0875 1.23125 18.7562 3.23125 20.7687C5.24375 22.7687 7.9125 23.875 10.75 23.875C16.4 23.875 21.0188 19.4438 21.3438 13.875H23.25C23.5938 13.875 23.875 13.5938 23.875 13.25C23.875 9.74375 22.5062 6.45 20.0312 3.96875C17.55 1.49375 14.2563 0.125 10.75 0.125ZM10.75 22.625C8.25 22.625 5.89375 21.65 4.11875 19.8813C2.35 18.1063 1.375 15.75 1.375 13.25C1.375 8.29375 5.25 4.23125 10.125 3.90625V13.25C10.125 13.5938 10.4062 13.875 10.75 13.875H20.0938C19.7688 18.75 15.7063 22.625 10.75 22.625ZM11.375 12.625V1.40625C14.3125 1.55625 17.05 2.7625 19.1437 4.85625C21.2375 6.95 22.4375 9.69375 22.5938 12.625H11.375Z"
        fill="#4C8991"
      />
    </svg>
  );
};

export default MBIcon;
