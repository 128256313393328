import React from "react";

const ToggleOn = () => {
  return (
      <svg width="47" height="24" viewBox="0 0 47 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.5" d="M35 0.5H12C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5H35C41.3513 23.5 46.5 18.3513 46.5 12C46.5 5.64873 41.3513 0.5 35 0.5Z" stroke="#1E1E1E"/>
          <path opacity="0.49" fill-rule="evenodd" clip-rule="evenodd" d="M21 12C21 16.97 16.97 21 12 21C7.03 21 3 16.97 3 12C3 7.03 7.03 3 12 3C16.97 3 21 7.03 21 12Z" fill="#1E1E1E"/>
      </svg>
  );
};

export default ToggleOn;
