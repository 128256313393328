import i18next from "i18next";

export const steps = [
  {
    target: "#outlined-basic",
    content: i18next.t("Paste the data you copied from a spreadsheet here (1/5)"),
    disableBeacon: true,
  },
  {
    target: "#step2",
    content:
      i18next.t("Click “Create PopStore” to convert your data into a PopStore (2/5)"),
    disableBeacon: true,
  },
  {
    target: "#step3",
    content: i18next.t("View analytics about your PopStores (3/5)"),
    disableBeacon: true,
  },
  {
    target: "#step4",
    content:
      i18next.t("Copy the link of your PopStore to share it with your customers (4/5)"),
    disableBeacon: true,
  },
  {
    target: "#step5",
    content:
      i18next.t("Click on your PopStore to view & edit your store, order, customers, and packing (5/5)"),
    disableBeacon: true,
  },
];
