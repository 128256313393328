import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Grid, TextField, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import Loading from "../../components/Loading";
import { ErrorAlert, SuccessAlert, } from "../../components/Styles/styledNotificationAlerts";
import { StyledTextField } from "../../components/Styles/styledTextField";
import { collection, db, doc, getDoc, serverTimestamp, setDoc, } from "../../service/firebase";
import { useTranslation } from "react-i18next";
import Total from "../../components/total";

const PopStore = () => {
  const {t} = useTranslation()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [store, setStore] = useState();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const { ownerId, storeId } = useParams();
  const [order, setOrder] = useState([]);
  const [usercurrency] = React.useState();
  const [storecurrency, setStoreCurrency] = React.useState();
  const [submitting, setSubmitting] = React.useState(false);
  const [storeLink, setStoreLink] = useState("");
  const [orderRef, setOrderRef] = useState(null);
  const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);
  const [lockedState, setLockedState] = useState();
  const [alert, setAlert] = useState({ type: "", message: "", subMessage:"" });
  const [openModal, setOpenModal] = useState(false);

  const handleAlert = async (type, message, subMessage="") => {
    await setAlert({ type, message, subMessage });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = async (e) => {
    setOpenModal(true);
  };

  let total = 0;
  const getData = async () => {
    // const res = await axios.get('https://geolocation-db.com/json/').then(res => {
    //   setIP(res.data.IPv4)
    //   setUserData(res.data)
    //   setUserCountry(res.data.country_name)
    //   convertCurrency(res.data.country_name)
    // })
  };
  const runconvertCurrency = async () => {
    // if(usercurrency){
    //   const res = await axios.get('https://api.currencyapi.com/v3/latest?apikey='+process.env.REACT_APP_CURRENCY_API_KEY+'&value=1&base_currency='+storecurrency+'&currencies='+usercurrency).then(res => {
    //     setConvertedPrice(res.data.data[usercurrency].value)
    //   });
    // }
  };
  React.useEffect(() => {
    setStoreLink(process.env.REACT_APP_STORE_LINK); // Set the store link using environment variable
  }, []);
  React.useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);
  React.useEffect(() => {
    //passing getData method to the lifecycle method
    runconvertCurrency();
  }, [storecurrency]);
  React.useEffect(() => {
    //passing getData method to the lifecycle method
    runconvertCurrency();
  }, [usercurrency]);
  useEffect(() => {
    (async () => {
      const storesRef = await collection(
        db,
        `/StoreOwners/${ownerId}/allStores`
      );
      const store = await getDoc(doc(storesRef, storeId));
      if (store.exists()) {
        let data = store.data();
        data.columnsList = JSON.parse(data.columnsList);
        let lock = data.locked;
        setStore(data);
        setStoreCurrency(data.currency);
        setLockedState(lock);
        setLoading(false);
      }
    })();
  }, [ownerId, storeId]);

  const saveOrder = async () => {
    setSubmitting(true);

    if (!isEmail(email) || email.trim() === "") {
      await handleAlert("error", t("Please enter your email"));
      handleOpenModal();
      setSubmitting(false);
      return;
    }

    if (!isMobilePhone(phone) || phone.trim() === "") {
      await handleAlert("error", t("Please enter your phone number"));
      handleOpenModal();
      setSubmitting(false);
      return;
    }

    if (order.length === 0) {
      await handleAlert("error", t("Please add some items to your order"));
      handleOpenModal();
      setSubmitting(false);
      return;
    }

    // Check if a customer with this email already exists
    const customersRef = await collection(
      db, `/StoreOwners/${ownerId}/allStores/${storeId}/customers`);
    const customer = await getDoc(doc(customersRef, email));
    if (!customer.exists()) {
      // Create a new customer
      const newCustomer = {
        uid: null,
        email: email.toLowerCase(),
        phone: phone,
        name: name,
        comment: comment,
        createdAt: serverTimestamp(),
      };
      let newCustomerRef = await doc(customersRef, email);
      await setDoc(newCustomerRef, newCustomer);
    }

    const Order = {
      uid: null,
      email: email.toLowerCase(),
      phone: phone,
      name: name,
      comment: comment,
      order: JSON.stringify(order),
      storeId: storeId,
      createdAt: serverTimestamp(),
    };

    const ordersRef = await collection(db,
        `/StoreOwners/${ownerId}/allStores/${storeId}/Orders`);
    const orderRef = await doc(ordersRef);
    await setDoc(orderRef, Order);
    setOrderRef(orderRef);

    const mailRef = await collection(db, `mail`);

    const emailRef = await doc(mailRef);

    let orderConfirmationEmail =  `<!DOCTYPE html>
        <html lang="en">
          <head>
            <style>
              body {
                font-family: "Open Sans", Arial, sans-serif;
              }
            </style>
            <title>PopStore Order</title>
          </head>
          <body
            style="
               padding-top: 20px;
               padding-bottom: 20px;
              background-color: #f5f5f5;
            "
          >
            <div
              style="
                width: 600px;
                height: auto;
                padding: 44px 68px;
                background-color: #fff;
                margin-left: auto;
                margin-right: auto;
              "
            >
              <p
                style="
                  font-family: 'Open Sans',sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 22px;
                  line-height: 30px;
                  color: #353535;
                  margin-bottom: 24px;
                ">
                ${t("Order Confirmation")}
              </p>
              <div
                style="
                  font-family: 'Open Sans',sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #353535;
                "
              >
              <p style="margin-bottom: 14px;">${t("Thank you for your order")}</p>
                <p style="margin-bottom: 38px;">
                  ${t("order_confirmation_text")} 
                </p>
                <p style="margin-bottom: 14px;">
                ${t('order_link')}    
                </p>
                <p style="margin-bottom: 47px;">
                  <a style="text-decoration-color: #353535; color: #353535" href="${storeLink}/order/${ownerId}/${storeId}/${orderRef.id}"
                    >${t("view_order")}</a>
                </p>
            
                <div style="line-height: 29px;">
                    <p>${t("subscription")}</p>
                </div>
              </div>
            </div>
          </body>
        </html>`;

    const mailData  = {
      to: [email.toLowerCase()],
      message: {
        subject: t("Order Confirmation"),
        text: "Sent from app",
        html: orderConfirmationEmail
      }
    }

    await setDoc(emailRef, mailData)

    await handleAlert("success", t("Your order has been created."), t("Thank you!"));
    handleOpenModal();

    setSubmitting(false);


    let newOrderEmail =  `<!DOCTYPE html>
        <html lang="en">
          <head>
            <style>
              body {
                font-family: "Open Sans", Arial, sans-serif;
              }
            </style>
            <title>PopStore Order</title>
          </head>
          <body
            style="
               padding-top: 20px;
               padding-bottom: 20px;
              background-color: #f5f5f5;
            ">
            <div
              style="
                width: 600px;
                height: auto;
                padding: 44px 68px;
                background-color: #fff;
                margin-left: auto;
                margin-right: auto;
              ">
              <p
                style="
                  font-family: 'Open Sans',sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 22px;
                  line-height: 30px;
                  color: #353535;
                  margin-bottom: 24px;
                ">
                ${t("New Order")} 
              </p>
              <div
                style="
                  font-family: 'Open Sans',sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #353535;
                ">
             
                <p style="margin-bottom: 38px;">
                  ${t("new_order_text")}.
                </p>
                <p style="margin-bottom: 14px;">
                    ${t('order_link')}    
                </p>
                <p style="margin-bottom: 47px;">
                  <a style="text-decoration-color: #353535; color: #353535"
                   href="${storeLink}/order/${ownerId}/${storeId}/${orderRef.id}">${t("view_order")}</a>
                </p>
            
                <div style="line-height: 29px;">
                    ${t("subscription")}
                </div>
              </div>
            </div>
          </body>
        </html>`;
    if (isEmail(store.storeOwner)) {
      const emailRef1 = await doc(mailRef);

      const mailData1  = {
        to: [store.storeOwner],
        message: {
          subject: t("New Order"),
          text: "Sent from app",
          html: newOrderEmail
        }
      }

      await setDoc(emailRef1, mailData1)
    }
  };

  const handleCopy = (e) => {
    const link = `${window.location.origin}${storeLink}/order/${ownerId}/${storeId}/${orderRef.id}`;
    // Copy the link to clipboard
    navigator.clipboard.writeText(link);
    //
    // Show snack bar
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) return <Loading />;
  return (
    <div>
      {lockedState ? (
        <Container
          maxWidth="lg"
          style={{
            width: "100%",
            minHeight: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "middle",
          }}>
          <div>
            <Typography
              variant="h5"
              align="center"
              fontWeight={500}
              fontSize="32px"
            >
              {t('This store is currently closed.')}
            </Typography>
          </div>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Grid container paddingBottom="25px" alignItems="center" spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant="h2">{store.storeName}</Typography>
            </Grid>
            <Grid item xs={6} md={5}>
              <Button
                  style={{ height: 40, width: 139, fontSize: 16, fontWeight: 400 }}
                variant="contained"
                startIcon={<ContentCopyIcon />}
                onClick={() => handleCopy()}
              >
                {t("Copy link")}
              </Button>
              <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{
                  vertical: "bottom", // Position the Snackbar at the bottom
                  horizontal: "center", // Center the Snackbar horizontally
                }}>
                <MuiAlert
                  onClose={handleCloseSnackbar}
                  severity="success"
                  sx={{
                    backgroundColor: "#656f71",
                    color: "#FFF",
                    "& .MuiAlert-icon": {
                      color: "#FFF",
                    },
                  }}
                >
                  Link Copied!
                </MuiAlert>
              </Snackbar>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="flex-end">
              <Grid item>
                <Button
                  style={{marginLeft: "1rem" , height: 40, width: 216, fontSize: 16, fontWeight: 400 }}
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  onClick={saveOrder}
                >
                  {isMobile ? t("Order") : t("Complete Order")}
                </Button>
                {alert.type === "error" && (
                  <ErrorAlert
                    open={openModal}
                    onClose={handleCloseModal}
                    message={alert.message}
                  />
                )}
                {alert.type === "success" && (
                  <SuccessAlert
                    open={openModal}
                    onClose={handleCloseModal}
                    message={alert.message}
                    subMessage={alert.subMessage}
                    navigate={handleCloseModal}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container paddingBottom="25px">
            <Grid item xs={12} md={3}>
              <Typography variant="body" className={'store-text-email'}>
                <span>{t("Contact")}: &nbsp;</span>
                {store.storeOwner}
              </Typography>
            </Grid>
          </Grid>
          <Grid container paddingBottom="25px">
            <Grid item xs={12} md={3}>
              <Typography variant="body" className={'store-text'}>{store.description}</Typography>
            </Grid>
          </Grid>
          <Grid container paddingBottom="25px" rowSpacing={2} columnSpacing={6}>
            <Grid item xs={12} md={3}>
              <div>
                <Typography variant="body" color="text.main">
                  {t("Name")}
                </Typography>
              </div>
              <StyledTextField className={'custom-input'}
                fullWidth
                id="outlined-basic"
                label=""
                helperText=""
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <div>
                <Typography variant="body" color="text.main">
                  {t("Email address")}
                </Typography>
              </div>
              <StyledTextField className={'custom-input'}
                fullWidth
                id="outlined-basic"
                label=""
                helperText=""
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <div>
                <Typography variant="body" color="text.main">
                  {t("Phone number")}
                </Typography>
              </div>
              <StyledTextField className={'custom-input'}
                fullWidth
                id="outlined-basic"
                label=""
                helperText=""
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <Typography variant="body" color="text.main">
                  {t("Would you like to add any note/ comment in your order?")}
                </Typography>
              </div>
              <StyledTextField className={'custom-textarea'}
                multiline
                fullWidth
                id="outlined-basic"
                label=""
                helperText=""
                variant="outlined"
                inputProps={{
                  style: {
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    height: 58
                  },
                }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Grid>
          </Grid>
          <div>
            <Grid
              container
              sx={{
                backgroundColor: "primary.main",
                paddingLeft: isMobile ? "2px" : "50px",
                display: "flex",
                alignItems: "center",
                height: "51px"
              }}
              pt="9px"
              pb="9px">
              <Grid item xs={2} md={3}>
                <Typography variant={isMobile ? "body2" : "h5"} className={'table-head'} color="white.main">
                  {t("Reference ID")}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography variant={isMobile ? "body2" : "h5"} className={'table-head'} color="white.main">
                  {t("Products")}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <Typography variant={isMobile ? "body2" : "h5"} className={'table-head'} color="white.main">
                  {t("Price")}
                </Typography>
              </Grid>
              <Grid item xs={3} md={2}>
                <Typography variant={isMobile ? "body2" : "h5"} className={'table-head'} color="white.main">
                  {t("Quantity")}
                </Typography>
              </Grid>
              <Grid item xs={1} md={2}>
                <Typography variant={isMobile ? "body2" : "h5"} className={'table-head'} color="white.main">
                  {t("Amount")}
                </Typography>
              </Grid>
            </Grid>
            <div>
              {store.columnsList?.map((column, index) => {
                return (
                  <Grid
                    container
                    key={index}
                    sx={{
                      paddingLeft: isMobile ? "2px" : "50px",
                      backgroundColor: "background2",
                      borderBottom: "1px solid",
                      borderTop: "1px solid",
                      fontSize: "14px",
                      borderTopColor: (theme) =>
                        alpha(theme.palette.primary.main, 0.3),
                      borderBottomColor: (theme) =>
                        alpha(theme.palette.primary.main, 0.3),
                      paddingTop: "9px",
                      paddingBottom: "9px",
                      "&:first-child": {
                        borderTop: "none",
                      },
                      "&:nth-last-child(2)": {
                        borderBottom: "none",
                      },
                    }}>
                    <Grid item xs={2} md={3}>
                      <p className={'tbl-body-text'}>{column[0]}</p>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <p className={'tbl-body-text'}>{column[1]}</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <p className={'tbl-body-text'}>
                        {column[2]} {store.currency}
                      </p>
                    </Grid>
                    <Grid item xs={3} md={2}>
                      <TextField
                        id="outlined-basic"
                        label=""
                        helperText=""
                        type="number"
                        variant="outlined"
                        value={order[index]?.quantity}
                        defaultValue={0}
                        disabled={store.locked}
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          width: "50%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid",
                            borderColor: "#353535",
                            borderRadius: "6px",
                          },
                          "& .MuiOutlinedInput-root": {
                            height: 34,
                            width: 65,
                            "&:hover": {
                              borderColor: (theme) =>
                                theme.palette.primary.main,
                            },
                            "&:focused": {
                              borderColor: (theme) =>
                                theme.palette.primary.main,
                            },
                          },
                        }}
                        onChange={(e) => {
                          if (!e.target.value || Number(e.target.value) < 0) {
                            e.target.value = (0).toString();
                            // set default value and value of TextField to 0
                            order[index] = {
                              ...order[index],
                              quantity: 0,
                              id: index,
                            };
                          } else {
                            let newOrder = [...order];
                            newOrder[index] = {
                              ...newOrder[index],
                              quantity: Number(e.target.value) < 0 ? 0 : parseInt(e.target.value),
                              id: index,
                              ref: column[0]
                            };
                            setOrder(newOrder);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <p className={'tbl-body-text'}>
                        {Number(
                          Number(column[2].replace(/,/, "")) *
                            Number(
                              Number(order[index]?.quantity)
                                ? order[index]?.quantity
                                : 0
                            )
                        ).toFixed(2)}{" "}
                        {store.currency}
                      </p>
                    </Grid>
                  </Grid>
                );
              })}
              <Total>
                {order?.forEach((item, index) => {
                  if (item) {
                    total +=
                        Number(item.quantity) *
                        Number(
                            (store?.columnsList[item.id][2]).replace(/,/, "")
                        );
                  }
                })}{" "}
                {Number(total).toFixed(2)} {store.currency}
              </Total>
            </div>
          </div>
          {!store.locked && (
            <div style={{ paddingTop: "10px" }}>
              <Grid container xs={12} md={12} justifyContent="flex-end">
                <Grid item>
                  <Button
                      style={{height: 40, width: 216, fontSize: 16, fontWeight: 400 }}
                    color="primary"
                    variant="contained"
                    disabled={submitting}
                    onClick={saveOrder}
                  >
                    {isMobile ? t("Order") : t("Complete Order")}
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}
        </Container>
      )}
    </div>
  );
};

export default PopStore;
