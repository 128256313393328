import { styled } from "@mui/material/styles";
import React from "react";
import { Box, Typography } from "@mui/material";
import HomeIcon from "../../icons/HomeIcon";
import MBIcon from "../../icons/MBIcon";
import { useTranslation } from "react-i18next";

const StyledBoxContainer = styled(Box)(({ theme }) => ({
  marginTop: "30px",
  paddingTop: "10px",
  paddingLeft: "17px",
  paddingRight: "17px",
  border: "1px solid",
  borderColor: "#000000",
  display: "flex",
  flexDirection: "column",
  borderRadius: "4px",
  width: "fit-content",
  minWidth: "166px",
  height: "86px",
}));

const StyledIndicatorWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "16px",
  alignItems: "center",
  height: "20px",
  width: "100%",
}));

export const PostoreIndicator = ({ popstores }) => {
  const {t} = useTranslation()
  return (
    <>
      <StyledBoxContainer id="step3">
        <Typography variant="body3" color="majorBlack">
          {t("My PopStores:")}
        </Typography>
        <StyledIndicatorWrapper>
          <Typography
            id="step3"
            variant="h3"
            color="primary.main"
            mr="16px"
            className={'analytics-text'}
          >
            {popstores}
          </Typography>
          <HomeIcon />
        </StyledIndicatorWrapper>
      </StyledBoxContainer>
    </>
  );
};

export const DataIndicator = ({ dataUsage }) => {
  const {t} = useTranslation()
  return (
    <>
      <StyledBoxContainer>
        <Typography variant="body3" color="majorBlack">
          {t("MB Usage")}
        </Typography>
        <StyledIndicatorWrapper>
          <Typography
            variant="h3"
            color="primary.main"
            mr="16px"
            className={'analytics-text'}>
            {parseFloat(dataUsage).toFixed(3)}
          </Typography>
          <MBIcon />
        </StyledIndicatorWrapper>
      </StyledBoxContainer>
    </>
  );
};
