import { collection, db, getDocs, query, where } from "./firebase";

export const getCustomersService = async (userId, storeId) => {
    const customersRef = collection(db, `/StoreOwners/${userId}/allStores/${storeId}/customers`);
    const Customers = await getDocs(customersRef);
    let temp = [];

    Customers.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        temp.push(doc.data());
    });

    return temp;
}

export const getCustomerOrders = async (userId, storeId, customerEmail) => {
    const ordersRef = collection(db, `/StoreOwners/${userId}/allStores/${storeId}/Orders`);
    const q = query(ordersRef, where("email", "==", customerEmail));
    const querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
        let d = doc.data();
        d.order = JSON.parse(d.order);
        temp.push(d);
    });
    let orders = [];
    temp.forEach((o) => {
        o.order.forEach((p) => {
            if (p !== null) {
                // check if order already exists
                let index = orders.findIndex((e) => e.id === p.id);
                if (index === -1) {
                    orders.push(p);
                } else {
                    orders[index].quantity += p.quantity;
                }
            }
        });
    });

    return orders;
};


export const getCustomerAndOrders = async (userId, storeId) => {
    let data = []

    const customers = await getCustomersService(userId, storeId);
    await Promise.all(
        customers.map(async (customer) => {
            data.push({
                customer: {
                    name: customer.name,
                    phone: customer.phone,
                    email: customer.email,
                },
                orders: await getCustomerOrders(userId, storeId, customer.email)
            })
        })
    )

    return data
}