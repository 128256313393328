import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

// Styled Button.
export const StyledNavButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "active",
})(({theme, active}) => ({
    padding: "8px 16px",
    fontSize: 16,
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    ...(active && {
        color: theme.palette.text.main,
    }),
    ...(!active && {
        color: theme.palette.primary.main,
    }),
}));
