import React from "react";

const ArrowUp = () => {
    return (
        <svg width="28" height="15" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3635 0.573555C16.3615 0.720249 16.3042 0.860791 16.2031 0.9671L8.50978 8.83637C8.4606 8.8889 8.40099 8.93059 8.33478 8.95877C8.26856 8.98695 8.19719 9.00099 8.12524 9.00001C7.97633 9.00001 7.84869 8.94519 7.74151 8.83637L0.160236 1.08165C0.107987 1.02899 0.0668644 0.966361 0.0393148 0.897487C0.0117651 0.828612 -0.00164847 0.7549 -0.000127922 0.680736C-0.000127922 0.522009 0.0530539 0.387827 0.160236 0.279009C0.266599 0.169373 0.397509 0.115373 0.551327 0.115373C0.624574 0.113799 0.697342 0.127542 0.76497 0.15572C0.832598 0.183898 0.893595 0.225891 0.944054 0.279009L8.12524 7.65574L15.4349 0.163645C15.5412 0.0548267 15.6697 8.58307e-06 15.8194 8.58307e-06C15.8911 -0.000746727 15.9622 0.0134096 16.0281 0.0415802C16.094 0.0697498 16.1533 0.111318 16.2023 0.163645C16.2551 0.21726 16.2961 0.281372 16.3226 0.351828C16.3493 0.422698 16.3629 0.497828 16.3627 0.573555H16.3635Z" fill="#4C8991"/>
        </svg>
    );
};

export default ArrowUp;
